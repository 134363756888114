import { useNavigate } from 'react-router-dom';
import {} from './index.css';

export default function NotFound(){
    const navigate = useNavigate();

    return ( 
        <main>
            <h1>Error 404 web no encontrada</h1>
            <h2>Lo siento, la web que estas buscando no existe</h2>
            <button onClick={()=>{
                navigate('/')
            }}>Volver al inicio</button>
        </main>
    )
}