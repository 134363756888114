import { useNavigate, useParams } from "react-router-dom"
import { useFetch } from "../../components/hooks/useFetch";
import { IoIosArrowBack } from "react-icons/io";
import './proyect.css';
export const Proyect = () =>{
    const navigate = useNavigate();
    const {id} = useParams();
    const {data:info} = useFetch(`https://taieldeluca.com.ar/api/portfolio/proyects/info/${id}`)
    const {data:tech} = useFetch(`https://taieldeluca.com.ar/api/portfolio/proyects/getTech/${id}`)
    
    return (
        <div className="proyect">
            <div className="proyect_header">
                <button onClick={()=>{navigate('/')}}>
                   <IoIosArrowBack />
                    Volver
                </button>

            </div>
            <div className="info">
                <div className="title">
                    <h1>{info?.title}</h1>
                    <div className="line"></div>
                    <h3>{info?.line}</h3>
                </div>
                <div className="subtitle">
                    <h3>{info?.description}</h3>
                    <div className="tech_list">
                        {tech?.map(item => (
                            <div className="tech" style={{backgroundColor:item.color}}>{item.name}</div>
                        ))}
                    </div>
                </div>

                <button onClick={()=>{window.open(info?.link,'_blank')}}>
                    Ir al proyecto
                </button>
            </div>
            <div className="image_container">
                <img src={info?.image}/>
            </div>
        </div>
    )
}