


//Default css
import './static/css/main.css';
import './static/css/variables.css';


import {Routes,Route} from 'react-router-dom'

import { LandingPage } from './pages/LandingPage';
import { Proyect } from './pages/Proyect/index.js';
import NotFound from './pages/NotFound/index.jsx';

function App() {
  
  return (
    
    <>
      <Routes  >
        <Route exact  path="" element={<LandingPage />} />
        <Route path="/proyect/:id" element={<Proyect />} />
        <Route path="*" element={<NotFound />} /> 
  
      </Routes>
    
      
    </>
  );
}

export default App;
