import './footer.css';

import linkedin from '../../static/images/icons/linkedin.svg';
import github from '../../static/images/icons/github.svg';
import gmail from '../../static/images/icons/mail.svg';
import instagram from '../../static/images/icons/instagram.svg';


function Footer(props){
    return (
        <footer>
            <nav>
               <a href="https://www.linkedin.com/in/taiel-de-luca-2056b5249/" target='_blank'><img src={linkedin} alt="" /></a>
               <a href="https://github.com/taielmatiasdeluca" target='_blank'><img src={github} alt="" /></a>
               <a href="mailto:taielmatiasdeluca@gmail.com" target='_blank'><img src={gmail} alt="" /></a>
               <a href="https://www.instagram.com/taieldeluca/" target='_blank'><img src={instagram} alt="" /></a>

            </nav>
            <div id="credits">
                taieldeluca © | 2024
            </div>
        </footer>
    );
}

export default Footer;