import { ThreeDots } from 'react-loader-spinner';
import './contact.css';
import { useEffect,useState } from 'react';

function Contact(props){
    const [errorEmail,setErrorEmail] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [errorName,setErrorName] = useState(false);
    const [loading,setLoading ] = useState(false);
    const [sended, setSended] = useState(false);
    const [mainError, setMainError] = useState(false);

    async function handleSubmit(e){
        e.preventDefault();

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        const form = document.getElementById('contact__form');
        const data = new FormData(form)
        console.log(data.get('email'))

        setErrorEmail(false);
        setErrorMessage(false);
        setErrorName(false);
        setSended(false);
        setMainError(false);

        if (!re.test(data.get('email')) ) {
            setErrorEmail(true)
        }

        if(data.get('name').length < 5){
            setErrorName('El nombre debe tener almenos 5 caracteres');
        }

        if(data.get('message').length < 10){
            setErrorMessage('El mensaje debe tener almenos 10 caracteres');
        }

        if(!errorEmail && !errorMessage && !errorName){
            setLoading(true)
            const response = await fetch('https://taieldeluca.com.ar/api/portfolio/email/new',{
                method:'post',
            body:data}
            );

            const info = await response.json();
            setLoading(false);
            if(info.errno == 400){
                setSended(true);
            }else{
                setMainError(info.error);
            }
        }


        
    }

    return (

        <div id="contact">

            <div className="title">
                Contacto
            </div>

            <div className="form">
                <div className="subtitle">
                    ¿ Algo para mi ?
                    <span>Cuentame tu proyecto/s y juntos lo haremos realidad</span>
                </div>
                <form onSubmit={handleSubmit} id='contact__form' >
                    {mainError && (<div className='error'>{mainError}</div>)}

                    <div className="item">
                        <label htmlFor="">Nombre</label>
                        {errorName && (<div className='error'>{errorName}</div>)}

                        <input type="text" name='name' />
                    </div>
                    <div className="item">
                        <label htmlFor="">Email</label>
                        {errorEmail && (
                        <div className="error">
                          El email no es valido  
                        </div>
                        )}
                        <input type="text" name='email' />
                       
                    </div>
                    <div className="item">
                        <label htmlFor="">Mensaje</label>
                        {errorMessage && (<div className='error'>{errorMessage}</div>)}
                        <textarea name="message" id="" cols="30" rows="10">

                        </textarea>
                    </div>

                    <div className="end">
                        <div className="mail">
                            <span class="material-icons">
                            email
                            </span>
                            taielmatiasdeluca@gmail.com
                        </div>

                        <button type="submit">
                            Enviar
                            {loading ? <ThreeDots
                                visible={true}
                                height="35"
                                width="35"
                                color="#D9D9D9"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""/> :  <span class="material-icons">
                                rocket_launch
                                </span>}
                            
                           
                        </button>
                        

                    </div>
                    {
                            sended && <span style={{color:'green',padding:'1rem'}}>Enviado con exito</span>
                        }
                        


                </form>
            </div>




     
        </div>
    );
}

export default Contact;