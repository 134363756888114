import './header.css';
import { useState,useEffect } from 'react';



function Header(props){
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position >= 600){
            setScrollPosition(true);
        }
        else{
            setScrollPosition(false);

        }
    };

    function scrollTo (id){
        let element = document.getElementById(id);
        element.scrollIntoView({behavior:"smooth", block: "start", inline:"nearest"});    
    }
    

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

   
    
    return (
        <>
        <header >
            <div className="title">
                Taiel
            </div>
            <nav>
                <li onClick={()=>{scrollTo('landing')}}>Inicio</li>
                <li onClick={()=>{scrollTo('about')}}>Sobre Mi</li>
                <li onClick={()=>{scrollTo('proyects_ref')}}>Proyectos</li>
                <li onClick={()=>{scrollTo('contact')}}>Contacto</li>
            </nav>

        </header>
        <div id='mini_header' className={scrollPosition && 'moved'}>
            
            <nav>
                <li onClick={()=>{scrollTo('landing')}}>Inicio</li>
                <li onClick={()=>{scrollTo('about')}}>Sobre Mi</li>
                <li onClick={()=>{scrollTo('proyects_ref')}}>Proyectos</li>
                <li onClick={()=>{scrollTo('contact')}}>Contacto</li>
            </nav>
        </div>


        </>
    );
}

export default Header;