import './about.css';

import { ParallaxBanner } from "react-scroll-parallax";
import { useRef } from 'react';

import image_png from '../../static/images/about_bg.png';
import bariloche from '../../static/images/barilo.webp';
import { Slide } from 'react-awesome-reveal';

function About(props){
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const image = windowSize.current[0] > 1250 ? image_png : bariloche;
    return (
      <div id="about">
        <ParallaxBanner
            layers={[
            { image: image,
                translateY: [0, 50],
                },
            
            ]}
            className="bg-container"
        >
            {windowSize.current[0] > 1250 &&  
         
                <div id="parralax_text">
                        <Slide >
                    <h2>Sobre Mi</h2>
                    <div className="description">
                    Hola soy Taiel, técnico informático apasionado de la programación, tengo experiencia creando diferentes proyectos de forma íntegra. 
                        <br /><br />
                        Me encantan los desafíos e investigar todo, pienso que la programación es impresionante, ya que no importa en qué ámbito te desarrolles, siempre le encontrás un uso.
                        <br /><br />
                        Me encanta ayudar a agrandar y mejorar la comunidad DEV por justamente por eso la  gran mayoría de mis proyectos se encuentran en mi <a target='_blank' href="https://github.com/taielmatiasdeluca">github</a>.
                    </div>

                    <h3>
                        Habilidades Principales
                    </h3>
                 
                    <div className="list">
                       
                        <div className="tech_item" style={{backgroundColor:'#7B7FB5'}}>PHP</div>
                        <div className="tech_item" style={{backgroundColor:'#0C9FCB'}}>REACT</div>
                        <div className="tech_item" style={{backgroundColor:'#F21C1F'}}>RUBY</div>
                        <div className="tech_item" style={{backgroundColor:'#3672BA'}}>JAVA</div>
                        <div className="tech_item" style={{backgroundColor:'#113527'}}>DJANGO</div>
                        <div className="tech_item" style={{backgroundColor:'#087CD8'}}>SQL</div>
                        <div className="tech_item" style={{backgroundColor:'#1C76B8'}}>CSS</div>
                        <div className="tech_item" style={{backgroundColor:'#FED650'}}>JS</div>
                        
                    </div>
                    </Slide>
                 

                </div>
            }
            

            
        </ParallaxBanner>
        {windowSize.current[0] < 1250 &&  
            <div id="parralax_text">
                <h2>Sobre Mi</h2>
                <div className="description">
                Hola soy Taiel, técnico informático apasionado de la programación, tengo experiencia creando diferentes proyectos de forma íntegra. 
                    <br /><br />
                    Me encantan los desafíos e investigar todo, pienso que la programación es impresionante, ya que no importa en qué ámbito te desarrolles, siempre le encontrás un uso.
                    <br /><br />
                    Me encanta ayudar a agrandar y mejorar la comunidad DEV por justamente por eso la  gran mayoría de mis proyectos se encuentran en mi <a target='_blank' href="https://github.com/taielmatiasdeluca">github</a>.
                </div>

                <h3>
                    Habilidades Principales
                </h3>
                <div className="list">
                    <div className="item" style={{backgroundColor:'#7B7FB5'}}>PHP</div>
                    <div className="item" style={{backgroundColor:'#0C9FCB'}}>REACT</div>
                    <div className="item" style={{backgroundColor:'#F21C1F'}}>RUBY</div>
                    <div className="item" style={{backgroundColor:'#3672BA'}}>JAVA</div>
                    <div className="item" style={{backgroundColor:'#113527'}}>DJANGO</div>
                    <div className="item" style={{backgroundColor:'#087CD8'}}>SQL</div>
                    <div className="item" style={{backgroundColor:'#1C76B8'}}>CSS</div>
                    <div className="item" style={{backgroundColor:'#FED650'}}>JS</div>
                </div>

            </div>
            }
      </div>
    );
}

export default About;