import './proyects.css';
import {useFetch}from '../hooks/useFetch.js';
import {useNavigate} from 'react-router-dom'


function Proyects(props){
    const {data} = useFetch("https://taieldeluca.com.ar/api/portfolio/proyects/list")
    const navigate = useNavigate();
    return (
        <div id="proyects">
            <div id="proyects_ref"></div>

            <div className="proyect_list">
                <h2>Proyectos</h2>
                <div className="list">
                    {data?.map(item =>(
                        <a class="card" onClick={()=>{navigate(`/proyect/${item.id}`)}}>
                        <div class="image_wrapper">
                            <img src={item.image} alt="" />
                        </div>
                        <div class="card_info">
                            <div class="heading">
                                <div class="title">{item.title}</div>
                                <div class="line"></div>
                                <div class="subtitle">
                                    {item.line}
                                </div>
                            </div>
                            <div class="subheding">
                                <div class="type">Desarrollo Web</div>
                                <div class="action">
                                    Ir al proyecto 
                                    <div class="line"></div>
                                </div>
                            </div>
                        </div>
                        </a>
                    ))}
                </div>
            </div>

            
        </div>
    );
}

export default Proyects;