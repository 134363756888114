import React from 'react';
import { useState,useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useFetch } from '../hooks/useFetch';


const MainContext = React.createContext();

function MainContextProvider({ children }) {
  const [loader,setLoader] = useState(false)

  function openLoader(){
    setLoader(true);

  }
  function closeLoader(){
    setLoader(false);
  }
  

  return (

    <MainContext.Provider value={{

      loader,
      openLoader,
      closeLoader,


    }}>
      {children}
    </MainContext.Provider>
  );
}

export { MainContext, MainContextProvider };
