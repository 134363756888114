import './landing.css';
import desk from '../../static/images/desk.svg';
import { ReactTyped } from 'react-typed';

function Landing(props){
    return (
        <div id="landing">

            <img src={desk} id='desk' alt="" />
            
            <div id="description" >
            Hola soy 
             <a onClick={(e)=>{
                e.preventDefault();
                let element = document.getElementById('about');
                element.scrollIntoView({behavior:"smooth",alignToTop:true, block: "end", inline:"nearest"});    
             }}> Taiel </a> 
            <br />
            un apacionado <br /> del mundo IT <br /> siempre con  <br /> ganas de <br />  <span >
               <ReactTyped
            backSpeed={50}
            strings={[
                'aprender',
                'crear',
                'descubrir',
                'programar',
                'diseñar'
            ]}
            loop={true}
            typeSpeed={120}
            />  </span><br /> algo nuevo, <br /> 
            y abierto a nuevas <br /> posibilidades
            </div>
        

        </div>
    );


}

export default Landing;