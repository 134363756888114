import About from "../../components/about/About"
import Contact from "../../components/contact/Contact"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import Landing from "../../components/landing/Landing"
import Proyects from "../../components/proyects/Proyects"

export const LandingPage = () =>{
    return  (
            <>
                <Header />
                <Landing />
                <About />
                <Proyects />
                <Contact />
                <Footer/>
            </>
            )
}